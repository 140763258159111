import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog9 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Johannes Brahms: Schicksalslied op. 54" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Johannes Brahms: Schicksalslied op. 54",
                link: "/k/9",
              },
            ]}
          />
          <PageTitle>Johannes Brahms: Schicksalslied op. 54</PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer
                src="katalog/09-johannes-brahms-schicksalslied"
                title="Johannes Brahms: Schicksalslied op. 54 – Gaechinger Cantorey der Bachakademie Stuttgart"
              />
              <Small color="whisper">
                Mit freundlicher Genehmigung der Gaechinger Cantorey,
                Bachakademie Stuttgart
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog9
